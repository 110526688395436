import * as React from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth } from 'aws-amplify';
import { Route, Link, Routes, useParams } from 'react-router-dom'
import axios from 'axios';
import '@aws-amplify/ui-react/styles.css';
import './App.css';

Amplify.configure({
	Auth: {
		identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		region: process.env.REACT_APP_REGION,
		userPoolId: process.env.REACT_APP_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
		mandatorySignIn: true
	}
});

const Home = (props) => {
	return (
		<div>
			<div className='eight columns'>
				<div className='contact-form' style={{'textAlign': 'right'}}>
					<div className='erabox' style={{'borderTop': '0'}}>
						<h5>Find by key</h5>
						<input name='key' type='text' value={props.token} onChange={(e) => props.setToken(e.target.value)} />
						<Link to={'/key/' + props.token}>
							<button type='submit' className='skyblue'><i className='fa-search'/>
								Find
							</button>
						</Link>
					</div>
					<div className='erabox'>
						<h5>Search by email</h5>
						<input name='email' type='email' value={props.email} onChange={(e) => props.setEmail(e.target.value)} />
						<Link to={'/email/' + props.email}>
							<button type='submit' className='skyblue'><i className='fa-search'/>
								Search
							</button>
						</Link>
					</div>
					<div className='erabox'>
						<h5>Search by ticket id</h5>
						<input name='ticketId' type='text' value={props.ticketId} onChange={(e) => props.setTicketId(e.target.value)} />
						<Link to={'/ticket/' + props.ticketId}>
							<button type='submit' className='skyblue'><i className='fa-search'/>
								Search
							</button>
						</Link>
					</div>
				</div>

			</div>
		</div>
	)
}

const Key = () => {
	const [tokenData, setTokenData] = React.useState('');
	const { token } = useParams();

	React.useEffect(() => {
		const loadData = async () => {
			const creds = await Auth.currentSession();
			const headers = {
				'Authorization': creds.idToken.jwtToken
			};
	
			try {
				const response = await axios.get(process.env.REACT_APP_API_URL + '/get/' + token, { 'headers': headers });
				if (response.data.data !== null && typeof response.data.data === 'object'){
					setTokenData(JSON.stringify(response.data.data));
					
				} else {
					setTokenData(response.data.data);
				}
			}
			catch(error) {
				console.error(error);
				setTokenData('Either there were no results or you need to refresh the page and log back in again.');
			}
		};
		loadData();
	});

	return (
		<div>
			<Link to={'/'}>Home</Link><br /><br />
			<h3>{ token }</h3>
			<br />
			<code className="secretData">{ tokenData }</code>
		</div>
	);
}

const Email = () => {

	const [emailData, setEmailData] = React.useState([]);
	const { email } = useParams();

	React.useEffect(() => {
		const loadData = async () => {
			const creds = await Auth.currentSession();
			const headers = {
				'Authorization': creds.idToken.jwtToken
			};
		
			try {
				const response = await axios.get(process.env.REACT_APP_API_URL + '/scan/email/' + email, { 'headers': headers })
				setEmailData(response.data);
			}
			catch (error) {
				console.error(error);
				setEmailData('Either there were no results or you need to refresh the page and log back in again');
			}
		};
		loadData();
	});

	return (
		<div>
			<Link to={'/'}>Home</Link><br /><br />
			<h3>Results for email: { email }</h3>
			<br />
			<div>
				{(() => {
					if (Array.isArray(emailData)) {

						if (emailData.length === 0) {
							return <div>
								There were no results
							</div>
						}

						return (
							<div>
								{
									emailData.map(item => { <p key={item}><Link to={'/key/' + item}>{item}</Link></p> })
								}
							</div>
						)
					} else {
						return <div>
							{ emailData }
						</div>
					}
				})()}
			</div>
		</div>
	)	
}

const Ticket = () => {

	const [ticketData, setTicketData] = React.useState([]);
	const { ticketId } = useParams();

	React.useEffect(() => {
		const loadData = async () => {
			const creds = await Auth.currentSession();
			const headers = {
				'Authorization': creds.idToken.jwtToken
			};

			try {
				const response = await axios.get(process.env.REACT_APP_API_URL + '/scan/ticket/' + ticketId, { 'headers': headers })
				setTicketData(response.data);
			}
			catch(error) {
				console.error(error);
				setTicketData('Either there were no results or you need to refresh the page and log back in again');
			}
		}
		loadData();
	});
	
	return (
		<div>
			<Link to={'/'}>Home</Link><br /><br />
			<h3>Results for Ticket Id: { ticketId }</h3>
			<br />
			<div>
				{(() => {
					if (Array.isArray(ticketData)) {

						if (ticketData.length === 0) {
							return <div>
								There were no results
							</div>
						}
						return (
							<div>
								{
									ticketData.map(function(item) {
										return <p key={item}><Link to={'/key/' + item}>{item}</Link></p>
									})
								}
							</div>
						)
					} else {
						return <div>
							{ ticketData }
						</div>
					}
				})()}
			</div>
		</div>
	)
};


function App() {

	const [token, setToken] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [ticketId, setTicketId] = React.useState('');
	
    return (
		<Authenticator loginMechanisms={['email']} signUpAttributes={[]} hideSignUp={true} >
			{({ signOut }) => (			
				<main>
					<div>
						<section className='blox'>
							<article>
								<div className='container'>
									<div className='sixteen columns alignleft'>
										<h2 className='extr'>Recover customer credentials</h2>
									</div>
								</div>
							</article>
						</section>
						<section className='container page-content'>
							<hr className='vertical-space3'/>
							<div>
								<div className='App-intro'>
									<Routes>
										<Route exact path='/' element={<Home token={token} setToken={setToken} email={email} setEmail={setEmail} ticketId={ticketId} setTicketId={setTicketId} />} />
										<Route path='/key/:token' element={<Key />} />
										<Route path='/email/:email' element={<Email email={email} />} />
										<Route path='/ticket/:ticketId' element={<Ticket ticketId={ticketId} />} />
									</Routes>
									
								</div>
							</div>
						</section>
					</div>
					<button onClick={signOut}>Sign out</button>
				</main>	
			)}
		</Authenticator>
	);
}
export default App;